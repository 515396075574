import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';

/**
 * Sentry 를 초기화 합니다.
 */
export const initSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],

    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.acloset\.net\//],

    environment: import.meta.env.DEV ? 'development' : import.meta.env.MODE,
    release: _APP_VERSION,

    beforeBreadcrumb: (breadcrumb, hint) => {
      // XHR 네트워크 후킹.
      if (breadcrumb.category === 'xhr') {
        return {
          ...breadcrumb,
          data: {
            requestID: hint?.xhr?._headers?.request_id,
            'x-amz-cf-id': hint?.xhr?.responseHeaders?.['x-amz-cf-id'],
            requestBody: hint?.xhr?.__sentry_xhr__.body.substring(0, 40),
            response: (hint?.xhr?.response != null ? hint.xhr?.response : hint?.xhr?._response)?.substring(0, 40),
            responseUrl: hint?.xhr?.responseURL,
          },
        };
      }
      return breadcrumb;
    },
  });

  Sentry.setTags({
    buildTimeMs: _BUILD_TIME_MS,
    devMode: import.meta.env.DEV,
    hardwareConcurrency: window.navigator.hardwareConcurrency,
    deviceMemory:
      'deviceMemory' in window.navigator && typeof window.navigator.deviceMemory === 'number'
        ? window.navigator.deviceMemory
        : 0,
  });
};
